#root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.icon-anim {
  animation: iconAnimation 55s ease infinite normal;
}

.icon-anim:nth-of-type(odd) {
  animation: iconAnimationOdd 55s ease infinite normal;
}

.header {
  color: white;
  font-size: 3em;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  margin-block-end: 0.25em;
}

.subheader {
  margin-block-start: 0.25em;
  color: yellow;
  font-family: 'Caveat', cursive;
}

html, head, body {
  margin: 0 auto;
  inset: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: rgb(88, 28, 135);
  background-image:
    radial-gradient(at 89% 100%, rgb(134, 25, 143) 0, transparent 22%),
    radial-gradient(at 58% 11%, rgb(147, 51, 234) 0, transparent 71%),
    radial-gradient(at 54% 61%, rgb(147, 51, 234) 0, transparent 50%),
    radial-gradient(at 91% 43%, rgb(22, 78, 99) 0, transparent 47%),
    radial-gradient(at 11% 41%, rgb(167, 139, 250) 0, transparent 79%),
    radial-gradient(at 81% 24%, rgb(6, 182, 212) 0, transparent 49%);
  background-size: 600% 600%;
  animation: moveBg 180s infinite linear;
}

@keyframes moveBg {
  0% {
    background-position: 55% 60%;
  }
  10% {
    background-position: 15% 85%;
  }
  20% {
    background-position: 20% 85%;
  }
  30% {
    background-position: 70% 80%;
  }
  40% {
    background-position: 90% 90%;
  }
  50% {
    background-position: 80% 80%
  }
  60% {
    background-position: 80% 60%;
  }
  70% {
    background-position: 90% 40%;
  }
  80% {
    background-position: 80% 10%;
  }
  90% {
    background-position: 10% 2%;
  }
  100% {
    background-position: 55% 60%;
  }
}

@keyframes iconAnimationOdd {
  0% {
    transform: scale(0.9) translate(0, -2px);
  }
  25% {
    transform: scale(1) translate(0, 3px);
  }
  50% {
    transform: scale(0.8) translate(0, 0);
  }
  75% {
    transform: scale(1) translate(0, 3px);
  }
  100% {
    transform: scale(0.9) translate(0, -2px);
  }
  
}

@keyframes iconAnimation {
  0% {
    transform: scale(1) translate(0, 3px);
  }
  25% {
    transform: scale(0.9) translate(0, -2px);
  }
  50% {
    transform: scale(1) translate(0, 0);
  }
  75% {
    transform: scale(0.9) translate(0, -2px);
  }
  100% {
    transform: scale(1) translate(0, 3px);
  }
}
